::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #6f6f6f;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #6f6f6f;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #6f6f6f;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6f6f6f;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6f6f6f;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #6f6f6f;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  -webkit-touch-callout: default;
  /* Allow default callout */
  -webkit-user-select: text;
  /* Allow text selection in WebKit-based browsers */
  -khtml-user-select: text;
  /* Allow text selection in Konqueror browser (outdated) */
  -moz-user-select: text;
  /* Allow text selection in Firefox */
  -ms-user-select: text;
  /* Allow text selection in Internet Explorer and Edge */
  user-select: text;
  /* Allow text selection in standard-compliant browsers */
  touch-action: manipulation;
  /* Optimize touch interactions */
  scrollbar-width: thin;
  /* Thin scrollbar width */
  scrollbar-color: #999 #fff;
  /* Custom scrollbar color */
}

body:-webkit-scrollbar-track {
  background: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #999;
  border-radius: 6px;
  border: 3px solid #fff;
}

.container {
  max-width: 1250px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: auto;
  padding: 0px 40px;
  position: relative;
  clear: both;
}

.melkontech_tm_all_wrap,
.melkontech_tm_all_wrap * {
  -webkit-box-sizing: border-box;
  /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;
  /* Firefox, other Gecko */
  box-sizing: border-box;
  /* Opera/IE 8+ */
}

.melkontech_tm_sticky_section {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  overflow: visible;
  top: 50px;
}

.melkontech_tm_sticky_section:after {
  clear: both;
  display: table;
  content: "";
}

/* TODO: Remove */

/* #preloader {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99999;
  display: flex;
}

#preloader:before,
#preloader:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 100%;
  z-index: -1;
  background-color: #000;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

#preloader:after {
  left: auto;
  right: 0;
}

#preloader .loader_line {
  margin: auto;
  width: 1px;
  height: 250px;
  position: relative;
  overflow: hidden;
  -webkit-transition: all 0.8s ease 0s;
  -o-transition: all 0.8s ease 0s;
  transition: all 0.8s ease 0s;
}

.loader_line:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  width: 1px;
  height: 0%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  -webkit-animation: lineheight 1000ms ease-in-out 0s forwards;
  -o-animation: lineheight 1000ms ease-in-out 0s forwards;
  animation: lineheight 1000ms ease-in-out 0s forwards;
}

.loader_line:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #999999;
  -webkit-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
  -webkit-animation: lineround 1200ms linear 0s infinite;
  -o-animation: lineround 1200ms linear 0s infinite;
  animation: lineround 1200ms linear 0s infinite;
  animation-delay: 2000ms;
}

@keyframes lineheight {
  0% {
    height: 0%;
  }

  100% {
    height: 100%;
  }
}

@keyframes lineround {
  0% {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    -o-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(200%);
    -ms-transform: translateY(200%);
    -o-transform: translateY(200%);
    transform: translateY(200%);
  }
} */

/*hiding all*/
.preloaded .loader_line:after {
  opacity: 0;
}

.preloaded .loader_line {
  opacity: 0;
  height: 100% !important;
}

.preloaded:before,
.preloaded:after {
  -webkit-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  -o-animation: preloadedzero 300ms ease-in-out 500ms forwards;
  animation: preloadedzero 300ms ease-in-out 500ms forwards;
}

@keyframes preloadedzero {
  0% {
    width: 50%;
  }

  100% {
    width: 0%;
  }
}

body.opened .melkontech_tm_topbar {
  transition-delay: 0.4s;
  transform: translateY(0);
}

.melkontech_tm_topbar.animate .wrapper {
  background-color: rgba(255, 255, 255, 1);
}

.melkontech_tm_topbar.animate .topbar_inner {
  padding: 20px 37px 20px 50px;
}

.melkontech_tm_topbar .list ul li.opened {
  opacity: 1;
  visibility: visible;
  left: 0px;
}

.melkontech_tm_topbar .list ul li a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_topbar .list ul li.current a {
  color: var(--secondary-color);
}

.trigger .hamburger-inner,
.trigger .hamburger-inner:after,
.trigger .hamburger-inner:before {
  height: 2px;
  width: 30px;
}

.trigger .hamburger {
  padding: 0px;
}

.melkontech_tm_mobile_menu .mobile_menu_inner {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

body.opened .melkontech_tm_hero .content .left {
  opacity: 1;
  visibility: visible;
  top: 0px;
}

.melkontech_tm_hero .services ul li a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_hero .services ul li a:hover .svg {
  transform: rotate(-50deg);
  color: var(--secondary-color);
}

body.opened .melkontech_tm_hero .content .right {
  opacity: 1;
  visibility: visible;
  top: 0px;
}

.melkontech_tm_hero .content .right .image .main {
  transform: rotate(0deg);
  z-index: 2;
  border-radius: 10px;
}

/* TODO: Remove */
/* body.opened .melkontech_tm_hero .content .right .image .main {
  transform: rotate(7deg);
} */

.melkontech_tm_hero .down {
  position: absolute;
  left: 0px;
  bottom: 8%;
  opacity: 0;
  visibility: hidden;

  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

body.opened .melkontech_tm_hero .down {
  opacity: 1;
  visibility: visible;
  bottom: 10%;
}

.melkontech_tm_hero .down .svg {
  width: 40px;
  height: 40px;
  animation: scroll ease 2s infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(20px);
  }
}

.melkontech_tm_hero .down a {
  text-decoration: none;
  color: #000;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_hero .down a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_hero .content .right .image .shape {
  position: absolute;
  top: 23px;
  bottom: -23px;
  left: -25px;
  width: 100%;
  /* background-color: var(--secondary-color); */
  transform: rotate(0deg);
  z-index: 1;
  border-radius: 10px;

  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/* TODO: Remove */
/* body.opened .melkontech_tm_hero .content .right .image .shape { */
/* transform: rotate(-6deg);
} */

.overlay {
  opacity: 0;
  visibility: hidden;
}

.list_inner:hover .overlay {
  opacity: 1;
  visibility: visible;
}

/* .details {
  opacity: 0;
  visibility: hidden;
}

.melkontech_tm_portfolio .portfolio_list .list_inner:hover .details {
  opacity: 1;
  visibility: visible;
}

.melkontech_tm_portfolio .portfolio_list .list_inner .svg {
  opacity: 0;
  visibility: hidden;
}

.melkontech_tm_portfolio .portfolio_list .list_inner:hover .svg {
  opacity: 1;
  visibility: visible;
} */

/* .melkontech_tm_swiper_progress {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  clear: both;
  margin-top: 27px;
} */

/* .melkontech_tm_swiper_progress .my_pagination_in {
  float: left;
  position: relative;
} */

/* .melkontech_tm_swiper_progress span {
  display: block;
  float: left;
  font-size: 16px;
  letter-spacing: 0;
  color: #000;
  height: 22px;
  line-height: 22px;
}

.melkontech_tm_swiper_progress .pagination_progress {
  width: 100px;
  margin: 0 20px;
  position: relative;
}

.melkontech_tm_swiper_progress .pagination_progress .all {
  height: 1px;
  width: 100%;
  position: absolute;
  z-index: 5;
  top: 0;
  top: 50%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.melkontech_tm_swiper_progress .pagination_progress .all span {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  transform-origin: left top;
  transform: scale(0);

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_swiper_progress .my_navigation {
  position: relative;
  top: 6px;
}

.melkontech_tm_swiper_progress .my_navigation ul {
  margin: 0px;
  list-style-type: none;
}

.melkontech_tm_swiper_progress .my_navigation ul li {
  margin: 0px 10px 0px 0px;
  display: inline-block;
}

.melkontech_tm_swiper_progress .my_navigation ul li:last-child {
  margin-right: 0px;
}

.melkontech_tm_swiper_progress .my_navigation ul li a {
  text-decoration: none;
  color: #000;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_swiper_progress .my_navigation ul li a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_swiper_progress .my_navigation ul li .svg {
  width: 27px;
  height: 27px;
}

.melkontech_tm_swiper_progress .my_navigation ul li .my_prev .svg {
  transform: rotate(180deg);
} */

.melkontech_tm_skills:before {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-image: url(../img/patterns/white-texture.png);
  background-repeat: repeat;
  opacity: 0.6;
  z-index: 2;
}

.melkontech_tm_skills:after {
  position: absolute;
  content: "";
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: #000;
  z-index: 1;
}

/* TODO: CLean */
/* .dodo_progress {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
} */

.progress_inner {
  width: 100%;
  margin-bottom: 25px;
}

.progress_inner:last-child {
  margin-bottom: 0px;
}

.progress_inner>span {
  margin: 0px 0px 11px 0px;
  width: 100%;
  display: block;
  text-align: left;
  color: #fff;
  font-family: var(--font-arimo);
}

.progress_inner span.number {
  float: right;
}

.progress_inner .background {
  background: rgba(255, 255, 255, 0.09);
  width: 100%;
  min-width: 100%;
  position: relative;
  height: 8px;
}

.progress_inner .background .bar_in {
  height: 100%;
  background: var(--secondary-color);
  width: 0px;
  overflow: hidden;
}

.progress_inner .background .bar {
  width: 0px;
  height: 100%;
}

.progress_inner .background .bar.open {
  -webkit-animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* Safari 4+ */
  -moz-animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* Fx 5+ */
  animation: wow 2s cubic-bezier(0.165, 0.84, 0.44, 1);
  /* IE 10+ */
  width: 100%;
}

@-webkit-keyframes wow {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@-moz-keyframes wow {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes wow {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

.melkontech_tm_skills .right[data-background-style="video"] .my_image {
  display: none;
}

.melkontech_tm_skills .right[data-background-style="image"] .my_video {
  display: none;
}

.melkontech_tm_timeline .timeline_list ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.melkontech_tm_timeline .timeline_list ul li:last-child {
  border-bottom: none;
}

.melkontech_tm_connect .right ul li .list_inner {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
}

.melkontech_tm_connect .right ul li .list_inner:hover {
  background-color: var(--secondary-color);
}

.melkontech_tm_connect .right ul li .list_inner:hover span {
  color: #fff;
}

.melkontech_tm_connect .right ul li .list_inner:hover .svg {
  color: #fff;
}

.overlay {
  opacity: 0;
  visibility: hidden;
}

.overlay {
  opacity: 1;
  visibility: visible;
}

/* .details {
  opacity: 0;
  visibility: hidden;
}

.list_inner:hover .details {
  opacity: 1;
  visibility: visible;
} */

.melkontech_tm_news .news_list .list_inner .svg {
  opacity: 0;
  visibility: hidden;
}

.melkontech_tm_news .news_list .list_inner:hover .svg {
  opacity: 1;
  visibility: visible;
}

.melkontech_tm_contact .fields ul li input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  border-radius: 5px;
}

.melkontech_tm_contact .fields ul li input:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.melkontech_tm_contact .fields .last textarea {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 50px !important;
  resize: none;
  margin-bottom: 20px;
  background-color: transparent;
}

.melkontech_tm_contact .fields .last textarea:focus {
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.melkontech_tm_contact .empty_notice {
  color: #f52225;
  margin-bottom: 7px;
  display: none;
  text-align: left;
  font-weight: 500;
}

.melkontech_tm_contact .contact_error {
  color: #f52225;
  text-align: left;
  font-weight: 500;
}

.melkontech_tm_contact .returnmessage {
  color: #3a00ff;
  margin-bottom: 7px;
  text-align: left;
  font-weight: 500;
}

.melkontech_tm_button {
  /* width: 100%; */
  height: auto;
  clear: both;
  float: left;
  border-radius: 5px;
  padding: 12px 24px;
}

.melkontech_tm_button {
  text-decoration: none;
  color: #fff;
  font-size: 17px;
  display: block;
  text-align: center;
  position: relative;
  background-color: var(--secondary-color);

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_button .svg {
  position: relative;
  top: -2px;
  width: 17px;
  height: 17px;
  left: 10px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_button:hover {
  color: #fff;
  background-color: #000;
}

.melkontech_tm_button:hover .svg {
  transform: rotate(-50deg);
}

/*COLOR SCHEMES*/

.melkontech_tm_topbar .list ul li.current a {
  color: var(--secondary-color);
}

.melkontech_tm_topbar .list ul li a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_hero .name {
  color: var(--secondary-color);
}

/* .melkontech_tm_hero .content .right .image .shape {
  background-color: var(--secondary-color);
} */

.melkontech_tm_hero .services ul li a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_hero .services ul li a:hover .svg {
  color: var(--secondary-color);
}

.melkontech_tm_hero .down a:hover {
  color: var(--secondary-color);
}

.melkontech_tm_main_title span {
  color: var(--secondary-color);
}

.melkontech_tm_swiper_progress .my_navigation ul li a:hover {
  color: var(--secondary-color);
}

.progress_inner .background .bar_in {
  background: var(--secondary-color);
}

.melkontech_tm_connect .right .svg {
  color: var(--secondary-color);
}

.melkontech_tm_contact .short ul li .list_inner .svg {
  color: var(--secondary-color);
}

.melkontech_tm_button {
  background-color: var(--secondary-color);
}

.melkontech_tm_button:hover {
  background-color: #000;
}

/*---------------------------------------------------*/
/*  KURA SETTINGS
/*---------------------------------------------------*/

.melkontech_tm_settings {
  position: fixed;
  z-index: 10;
  top: 25%;
  right: -200px;
  width: 200px;
  background-color: rgba(0, 0, 0, 1);
  padding: 40px 20px 33px 30px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_settings.opened {
  right: 0px;
}

.melkontech_tm_settings .wrapper {
  width: 100%;
  height: auto;
  clear: both;
  float: left;
}

.melkontech_tm_settings .icon {
  position: absolute;
  top: 0px;
  right: 100%;
  background-color: #000;
  padding: 12px;
}

.melkontech_tm_settings .icon .svg {
  color: #fff;
  width: 25px;
  height: 25px;
  animation: fa-spin 2s infinite linear;
}

.melkontech_tm_settings .link {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.melkontech_tm_settings .title {
  color: #fff;
  font-family: var(--font-arimo);
  font-weight: 600;
  position: relative;
  display: inline-block;
}

.melkontech_tm_settings .title:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  left: 0px;
  bottom: 0px;
}

.melkontech_tm_settings .colors {
  margin: 0px 0px 22px 0px;
  list-style-type: none;
  padding-top: 32px;
}

.melkontech_tm_settings .colors li {
  margin: 0px;
  display: inline-block;
}

.melkontech_tm_settings .colors li a {
  text-decoration: none;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.melkontech_tm_settings .direction {
  list-style-type: none;
  margin: 0px;
}

.melkontech_tm_settings .direction li {
  margin: 0px 20px 0px 0px;
  display: inline-block;
}

.melkontech_tm_settings .direction li a {
  opacity: 0.4;
  text-decoration: none;
  color: #fff;
}

.melkontech_tm_settings .direction li a.active {
  opacity: 1;
}

.melkontech_tm_settings .direction li .svg {
  width: 25px;
  height: 25px;
}

.melkontech_tm_settings .colors li.bl {
  display: inline-block;
}

.melkontech_tm_settings .colors li.wh {
  display: none;
}

body.dark .melkontech_tm_settings .colors li.bl,
.melkontech_tm_settings.changed .colors li.bl {
  display: none;
}

body.dark .melkontech_tm_settings .colors li.wh,
.melkontech_tm_settings.changed .colors li.wh {
  display: inline-block;
}

body.dark .melkontech_tm_settings,
.melkontech_tm_settings.changed {
  background-color: #fff;
}

body.dark .melkontech_tm_settings .icon,
.melkontech_tm_settings.changed .icon {
  background-color: #fff;
}

body.dark .melkontech_tm_settings .icon .svg,
.melkontech_tm_settings.changed .icon .svg {
  color: #000;
}

body.dark .melkontech_tm_settings .title,
.melkontech_tm_settings.changed .title {
  color: #000;
}

body.dark .melkontech_tm_settings .title:before,
.melkontech_tm_settings.changed .title:before {
  background-color: rgba(0, 0, 0, 0.5);
}

body.dark .melkontech_tm_settings .direction li a,
.melkontech_tm_settings.changed .direction li a {
  color: #000;
}

body.dark .melkontech_tm_settings .cursor li:nth-child(1) a,
.melkontech_tm_settings.changed .cursor li:nth-child(1) a {
  border-color: #000;
}

body.dark .melkontech_tm_settings .cursor li:nth-child(1) a:before,
.melkontech_tm_settings.changed .cursor li:nth-child(1) a:before {
  background-color: #000;
}

body.dark .melkontech_tm_settings .cursor li .svg,
.melkontech_tm_settings.changed .cursor li .svg {
  color: #000;
}

.melkontech_tm_settings .cursor {
  margin: 0px;
  list-style-type: none;
  padding-top: 20px;
}

.melkontech_tm_settings .cursor li {
  margin: 0px 20px 0px 0px;
  display: inline-block;
}

.melkontech_tm_settings .cursor li a {
  text-decoration: none;
  color: #fff;
  opacity: 0.4;
  font-weight: 600;
}

.melkontech_tm_settings .cursor li a.showme {
  opacity: 1;
}

.melkontech_tm_settings .cursor li:nth-child(1) a {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid #fff;
  position: relative;
  display: inline-block;
}

.melkontech_tm_settings .cursor li:nth-child(1) a:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.melkontech_tm_settings .cursor li .svg {
  width: 25px;
  height: 25px;
  color: #fff;
}

.melkontech_tm_settings .cursor li:nth-child(2) {
  position: relative;
  top: -2px;
}

.melkontech_tm_settings .effect {
  margin: 0px;
  list-style-type: none;
  padding-top: 15px;
  padding-bottom: 25px;
}

.melkontech_tm_settings .effect li {
  margin: 0px 0px 5px 0px;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 100%;
  background-color: #fff;
  opacity: 0.4;
}

.melkontech_tm_settings .effect li a {
  text-decoration: none;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  font-family: var(--font-arimo);
  display: block;
  position: relative;
  top: 2px;
}

.melkontech_tm_settings .effect li.selected {
  background-color: #fff;
  opacity: 1;
}

/*---------------------------------------------------*/
/*	 KURA INTRO
/*---------------------------------------------------*/

.melkontech_tm_intro .short_info {
  position: fixed;
  top: 10%;
  width: 100%;
  text-align: center;
}

.melkontech_tm_intro .short_info img {
  margin-bottom: 25px;
  max-width: 110px;
}

.melkontech_tm_intro .short_info h3 {
  font-size: 20px;
  color: #767676;
}

.melkontech_tm_intro {
  width: 100%;
  height: 100vh;
  clear: both;
  float: left;
  position: relative;
}

.melkontech_tm_intro .demos {
  width: 100%;
  height: 100%;
  clear: both;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}

.melkontech_tm_intro .left,
.melkontech_tm_intro .right {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.melkontech_tm_intro .demos img {
  max-width: 400px;
  margin-bottom: 25px;
}

.melkontech_tm_intro .desc {
  position: relative;
  top: 0px;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.melkontech_tm_intro .desc h3 {
  font-size: 20px;
  font-weight: 800;
}

.melkontech_tm_intro .intro_line,
.melkontech_tm_intro .intro_line_2,
.melkontech_tm_intro .intro_line_3 {
  position: fixed;
  top: 0px;
  width: 1px;
  height: 200%;
  left: 50%;
  transform: translateY(-50%);
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.06);
}

.melkontech_tm_intro .intro_line {
  left: 25%;
}

.melkontech_tm_intro .intro_line_2 {
  left: 50%;
  transform: translateY(-50%);
}

.melkontech_tm_intro .intro_line_3 {
  left: 75%;
}

.melkontech_tm_intro .intro_link {
  position: absolute;
  z-index: 2;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.melkontech_tm_intro .left:hover .desc {
  top: -20px;
}

.melkontech_tm_intro .right:hover .desc {
  top: -20px;
}

.melkontech_tm_intro_fixed_connect .connect-info,
.melkontech_tm_intro_fixed_connect .anim {
  text-decoration: none;
  color: #fff;
  font-family: var(--font-montserrat);
  background-color: #e54b4b;
  position: fixed;
  font-size: 22px;
  text-align: center;
  z-index: 3;
  border-radius: 100%;
  height: 70px;
  width: 70px;
  line-height: 70px;
  display: inline-block;
  left: 50%;
  bottom: 10%;
  margin-left: -35px;
  font-weight: 600;

  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

@-webkit-keyframes myAnim {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

@keyframes myAnim {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    opacity: 0;
  }
}

.melkontech_tm_intro_fixed_connect .anim:nth-child(1) {
  -webkit-animation: myAnim 2s infinite;
  animation: myAnim 2s infinite;
}

.melkontech_tm_intro_fixed_connect .anim:nth-child(2) {
  -webkit-animation: myAnim 2s infinite 0.3s;
  animation: myAnim 2s infinite 0.3s;
}

.melkontech_tm_intro_fixed_connect .anim:nth-child(3) {
  -webkit-animation: myAnim 2s infinite 0.6s;
  animation: myAnim 2s infinite 0.6s;
}